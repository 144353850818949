/* eslint-disable @typescript-eslint/restrict-template-expressions */
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LogoutIcon from '@mui/icons-material/Logout';
import { Typography } from '@mui/material';
import Avator from 'assets/images/d-avator.svg';
// import Setting from 'assets/images/setting.svg';
import {
  AdminPrivateRoutes,
  AttorneyPrivateRoutes,
  BeneficiaryPrivateRoutes,
  PetitionerPrivateRoutes,
  PublicRoutes,
} from 'config';
import _ from 'lodash';
import React, { ReactElement, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { logout } from 'store/actions/UserAction';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fileApiUrl } from 'utils/Config';
import { alertOptions } from 'utils/helper';
import { NavBarLists } from 'utils/NavbarConstant';

import { AffilatePrivateRoutes } from '../config/index';
import InfoMessage from './InfoMessage';

const Navbar: React.FC<any> = ({ menuOpen, setMenuOpen }): ReactElement => {
  const { userInfo, sideMenu, profile }: any = useAppSelector(
    (store: any) => store.userLogin,
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState<any>(false);
  const [subMenuOpen, setSubMenuOpen] = useState<any>({
    index: null,
    show: false,
  });
  const [navBarList, setNavBarList] = useState<any>([]);
  const [avatorImg, setAvatorImg] = useState(Avator);

  const submitLogout = () => {
    dispatch(logout());
    toast.success('Logged out successfully', alertOptions);
    setMenuOpen(false);
    navigate(PublicRoutes.LOGIN);
  };

  const handleOpen = () => setOpen(userInfo.show_popup);

  const handleClose = () => {
    sessionStorage.setItem('show_popup', 'true');
    setOpen(false);
  };

  useEffect(() => {
    if (sideMenu && !_.isEmpty(sideMenu?.sideMenuArray)) {
      const menuList = [];
      NavBarLists.navBarIcons.filter((navbarValue) => {
        const menuIndex = sideMenu?.sideMenuArray.findIndex(
          (indexval) => indexval.moduleId === navbarValue.menuListId,
        );
        if (menuIndex !== -1) {
          const menu = { ...navbarValue };
          menu.subnav = [];
          if (navbarValue.flag) {
            menu.subnav = navbarValue.subnav.filter((subMenu) => {
              const subMenuIndex = sideMenu?.sideMenuArray[
                menuIndex
              ].moduleList.findIndex(
                (subIndexVal) => subIndexVal.screen_id === subMenu.screenId,
              );
              if (subMenuIndex !== -1) {
                const subTab = sideMenu?.sideMenuArray[menuIndex].moduleList[
                  subMenuIndex
                ].tabList.find((tab) => tab.allow);
                if (subTab) {
                  if (subMenu.tabList && subMenu.tabList.length) {
                    const findTabIndex = subMenu.tabList.findIndex(
                      (customTab) => customTab.tabId === subTab.tab_id,
                    );
                    const additionalRoutePath =
                      subMenu.tabList[findTabIndex] &&
                      subMenu.tabList[findTabIndex].add_router
                        ? subMenu.tabList[findTabIndex].add_router
                        : '';
                    subMenu.aditionalRoute = additionalRoutePath;
                  }
                }
                return subMenu.screenId == 44 && !userInfo.is_subscription_need
                  ? null
                  : subMenu;
              }
            });
          }
          menuList.push(menu);
        }
      });
      setNavBarList(menuList);
    }
    handleOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sideMenu]);

  useEffect(() => {
    if (profile && profile.image) {
      fetch(`${fileApiUrl}${String(profile.image)}`).then((res) => {
        res.ok
          ? setAvatorImg(`${fileApiUrl}${String(profile.image)}`)
          : setAvatorImg(Avator);
      });
    } else {
      setAvatorImg(Avator);
    }
  }, [profile]);

  // useEffect(() => {

  // }, [menuOpen]);

  return (
    <>
      <Typography
        className={
          menuOpen ? 'setting-icon menubar' : 'setting-icon menubar-close'
        }
        component={'div'}>
        <Typography
          component={'div'}
          className={
            menuOpen ? 'sidebar main-menubar' : 'sidebar main-menubar-close'
          }>
          <Typography component={'div'} className="d-flex-ja flex-column">
            <img src={String(avatorImg)} alt="avator" className="avatar" />
            <Typography component={'div'} className="s-name black-white-text">
              {profile?.full_name}
            </Typography>
            <Typography component={'div'} className="s-mail black-white-text">
              {profile?.email_id}
            </Typography>
          </Typography>
          <ul className="main-submenu">
            <NavLink
              to={
                userInfo.role_id === 1
                  ? AdminPrivateRoutes.CHANGEPASSWORD
                  : userInfo.role_id === 2
                  ? PetitionerPrivateRoutes.CHANGEPASSWORD
                  : userInfo.role_id === 3
                  ? AttorneyPrivateRoutes.CHANGEPASSWORD
                  : userInfo.role_id === 4
                  ? BeneficiaryPrivateRoutes.CHANGEPASSWORD
                  : AffilatePrivateRoutes.CHANGEPASSWORD
              }
              className={({ isActive }) => (isActive ? 'active' : 'inactive')}
              onClick={() => {
                setMenuOpen(false);
              }}>
              <li
                className="black-white-text m-li"
                style={{ borderTop: '1px solid #002c69' }}>
                Change Password
              </li>
            </NavLink>
            {navBarList.length > 0
              ? navBarList.map((navBar: any, index: number) => (
                  <Typography component={'div'} className="cl-box" key={index}>
                    {!navBar?.flag && navBar.menuListId !== 6 && (
                      <NavLink
                        to={`/${String(userInfo?.userRoleName)}${
                          navBar?.routerLink
                        }`}
                        className={({ isActive }) =>
                          isActive ? 'active' : 'inactive'
                        }
                        onClick={() => {
                          setMenuOpen(false);
                        }}>
                        <li className="black-white-text m-li">
                          {navBar.menuList}
                        </li>
                      </NavLink>
                    )}
                    {!navBar?.flag &&
                      navBar.menuListId === 6 &&
                      userInfo.role_id !== 1 && (
                        <a href={navBar.routerLink}>
                          <li className="black-white-text m-li">
                            {navBar.menuList}
                          </li>
                        </a>
                      )}
                    {navBar?.flag && (
                      <Typography
                        component={'div'}
                        // to={true}
                        className={
                          window.location.href.includes(
                            String(navBar?.menuList).toLowerCase(),
                          )
                            ? 'active'
                            : 'inactive'
                        }>
                        <li
                          className="black-white-text m-li cl"
                          onMouseEnter={() =>
                            setSubMenuOpen({ index, show: true })
                          }
                          onMouseLeave={() => setSubMenuOpen({ show: false })}>
                          {navBar.menuList === 'Petitions'
                            ? 'Client Journey'
                            : navBar.menuList}
                          <ArrowForwardIosIcon className="menu-arrow" />
                          {subMenuOpen.index === index && subMenuOpen.show && (
                            <ul
                              className={
                                !subMenuOpen
                                  ? 'submenu-list-hide'
                                  : 'submenu-list-show'
                              }>
                              {navBar?.subnav.map(
                                (submenu: any, subIndex: number) => (
                                  <NavLink
                                    to={`/${String(userInfo?.userRoleName)}${
                                      submenu?.routerLink
                                    }${
                                      submenu?.aditionalRoute
                                        ? submenu?.aditionalRoute
                                        : ''
                                    }`}
                                    className={({ isActive }) =>
                                      isActive ? 'active' : 'inactive'
                                    }
                                    onClick={() => {
                                      setSubMenuOpen({ show: false });
                                      setMenuOpen(false);
                                    }}
                                    key={subIndex}>
                                    <li
                                      className="black-white-text"
                                      key={subIndex}>
                                      {submenu?.menu}
                                    </li>
                                  </NavLink>
                                ),
                              )}
                            </ul>
                          )}
                        </li>
                      </Typography>
                    )}
                  </Typography>
                ))
              : ''}
          </ul>
          <Typography component="div" className="logout-box">
            <Typography
              component={'div'}
              className="black-white-text d-flex-a f-14 l-btn"
              onClick={submitLogout}>
              <LogoutIcon />
              Logout
            </Typography>
          </Typography>
        </Typography>
        {/* <img src={Setting} alt="setting" /> */}
        <Typography
          component={'div'}
          className="d-flex-a"
          sx={{
            backgroundColor: '#002c69',
            borderRadius: '5px 0px 0px 5px',
            color: '#fff !important',
            fontFamily: 'Arial-Bold !important',
            padding: '10px',
            transform: 'rotate(180deg)',
            writingMode: 'vertical-rl',
          }}
          onClick={() => {
            setMenuOpen(!menuOpen);
          }}>
          Menu
        </Typography>
      </Typography>
      <InfoMessage
        showManageUser={true}
        open={open}
        close={handleClose}
        infoMessage={userInfo.info_message}
      />
    </>
  );
};

export default Navbar;
